<template>
	<div id="room">
		<!-- <div class="TimeDown" v-show="RollData.status == '1'">
			<p class="TimeDown_txt">倒计时:</p>
			<van-count-down :time="new Date(RollData.end_time).getTime() - new Date().getTime()">
				<template #default="timeData">
					<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
					<span class="colon">:</span>
					<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
					<span class="colon">:</span>
					<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
				</template>
			</van-count-down>
		</div> -->

		<div class="room_data">
			<div class="room_top">
				<div class="room_pic">
					<img :src="Avatar" alt="">
				</div>
				<div class="room_desc">
					<div class="room_tit">{{RollData.name}}</div>
					<p>{{RollData.describe}}</p>
				</div>
			</div>
			<div class="room_bottom">
				<div class="room_info">
					<p>开奖时间:</p>
					<span>{{RollData.end_time}}</span>
				</div>
				<div class="TimeDown" v-show="RollData.status == '1'">
					<p class="TimeDown_txt">倒计时:</p>
					<van-count-down :time="new Date(RollData.end_time).getTime() - new Date().getTime()">
						<template #default="timeData">
							<span class="block" v-text="timeData.days < 10 ? '0'+timeData.days : timeData.days"></span>
							<span class="colon">天</span>
							<span class="block" v-text="timeData.hours < 10 ? '0'+timeData.hours : timeData.hours"></span>
							<span class="colon">时</span>
							<span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.minutes"></span>
							<span class="colon">分</span>
							<!-- <span class="block" v-text="timeData.minutes < 10 ? '0'+timeData.minutes : timeData.second"></span> -->
						</template>
					</van-count-down>
				</div>
				
				<div v-show="RollData.status == '1'">
					<van-field center clearable v-model="Password" type="password" label="密码" v-if="RollData.is_pwd == 1">
						<!-- <template #button>
							<van-button size="small" type="info" @click="GetRoomJoin">加入房间</van-button>
						</template> -->
					</van-field>
					<img src="../../assets/images/q2/rollin/add.png" alt="" style="width:150px" @click="GetRoomJoin">
					
					<!-- <van-button type="info" v-else @click="GetRoomJoin">加入房间</van-button> -->
					<div></div>
				</div>
			</div>
		</div>

		<div class="prize">
			<div class="prize_tit">活动奖池</div>
			<div class="prize_nav">
				
				<div class="prize_info">
					<p>共<span>{{prizeList.length}}</span>件</p>
					<p>总价值:<span style="color: #f1bc60;"><img src="@/assets/images/public/Gold.png" alt="">{{RollData.award_bean}}</span></p>
					<p class="mores" @click="mores">查看更多</p>
				</div>
			</div>
			<div class="prize_list">
				<div class="prize_item" v-for="(item,index) in prizeList" :key="index" :class="item.lv | LvGuoLv">
					<p>{{item.name}}</p>

					<div class="prize_pic" :style="{'background-image': 'url('+item.lv_bgImage+')'}">
						<img :src="item.cover" alt="">
					</div>
					<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
				</div>
			</div>
		</div>


		<div class="Award" v-if="RollData.is_give == '1'">
			<div class="Award_nav">
				<div class="Award_tit">获奖名单</div>
			</div>
			<div class="Award_list">
				<div class="Award_item" v-for="(item,index) in prizeList" :key="index">
					<div style="display:flex;align-items: center;">
						<div class="Award_portrait">
							<img :src="item.user.avatar" alt="">
						</div>
						<div class="Award_name">{{item.user.name}}</div>
					</div>
					<div class="Award_pic" :style="{'background-image': 'url('+item.lv_bgImage+')'}">
						<img :src="item.cover" alt="">
					</div>
					<!-- <p>{{item.name}}</p> -->
					<span><img src="@/assets/images/public/Gold.png" alt="">{{item.bean}}</span>
				</div>
			</div>
		</div>

		<div class="player">
			<div class="player_tit">参与人数</div>
			<div class="player_nav">
				
				<div class="player_info">
					<p>共<span>{{room_users.length}}</span>人</p>
				</div>
			</div>
			<div class="player_list">
				<div class="player_item" v-for="(item,index) in room_users" :key="index">
					<div class="player_pic">
						<img :src="item.avatar" alt="">
					</div>
					<p>{{item.name}}</p>
				</div>
			</div>
		</div>
		<div class="zhe" v-show="moreshow==true">
			<div class="centers">
				<van-icon name="cross" @click="moreshow=false" class="closes" size="25" />
				<div class="litcenter">
					<div class="prize_list">
						<div class="prize_item" v-for="(item,index) in prizeList" :key="index" :class="item.lv | LvGuoLv">
							<p>{{item.name}}</p>

							<div class="prize_pic">
								<img :src="item.cover" alt="">
							</div>
							<span><img src="@/assets/images/public/Gold.png" alt="" style="width: 10px;height: 10px;">{{item.bean}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		RoomDetail,
		RoomJoin
	} from '@/network/api.js'
	export default {
		name: 'room',
		data() {
			return {
				time: 30 * 60 * 60 * 1000,
				RollData: {}, //房间信息
				Avatar:'',//房主头像
				prizeList: [], //物品列表
				room_users:[],//玩家列表
				Password:'',//Roll密码
				moreshow:false
			}
		},
		filters: {
			LvGuoLv(val){
				if (val == '1') {
					return 'lv1'
				} else if (val == '2') {
					return 'lv2'
				} else if (val == '3') {
					return 'lv3'
				} else if (val == '4') {
					return 'lv4'
				}else if (val == '5') {
					return 'lv5'
				}else if (val == '6') {
					return 'lv6'
				}
			},
		},
		created() {
			this.GetRoomDetail()
		},
		methods: {
			// close(){

			// },
			mores(){
				this.moreshow=true
			},
			//房间详情
			GetRoomDetail() {
				RoomDetail(this.$route.query.id).then((res) => {
					// console.log(res.data.data)
					this.Avatar = res.data.data.user.avatar
					this.RollData = res.data.data
					this.prizeList = res.data.data.box_records
					this.room_users = res.data.data.room_users
				})
			},
			
			//加入房间
			GetRoomJoin(){
				RoomJoin(this.$route.query.id,this.Password).then((res) => {
					// console.log(res)
					this.$notify({
						type: 'success',
						message: res.data.message
					})
				})
			}
		}
	}
</script>

<style lang="scss">

.zhe{
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	right: 0;
	z-index: 999;
	.centers{
		width: 90%;
		height: 70%;
		background: white;
		margin: 20% auto;
		
		background: url('../../assets/images/q2/openbox/xiangbac.png') no-repeat center;
		background-size: 100% 100%;
		padding: 10px;
		padding-top: 30px;
		position: relative;
		.closes{
			position: absolute;
			right: 20px;
			top: 20px;
			width: 20px;
		}
		.litcenter{
			width: 100%;
			height: 93%;
			margin-top: 10%;
			overflow: scroll;
			color: white;
			font-size: 22px;
		}
		.prize_list{
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			color: #fff;
			.prize_item{
				width: 48%;
				height: 85px;
				display: inline-block;
				border-radius: 4px;
				margin-right: 5px;
				background: rgba($color: #fff, $alpha: .03);
				display: flex;
				flex-wrap: wrap;
				align-content: center;
				justify-content: center;
				margin-top: 10px;
				&.lv1{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgba(233, 212, 90, 0.358);
					// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(139, 111, 18));
					background: url('../../assets/images/985/roll/1.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv2{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgb(210, 73, 73);
					// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(133, 46, 46));
					background: url('../../assets/images/985/roll/2.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv3{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgb(203, 70, 203);
					// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(57,44,83));
					background: url('../../assets/images/985/roll/3.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv4{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgb(86, 86, 214);
					// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(57,79,102));
					background: url('../../assets/images/985/roll/4.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv5{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgb(96, 96, 113);
					// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(87, 92, 104));
					background: url('../../assets/images/985/roll/5.png') no-repeat center;
					background-size: 100% 100%;
				}
				&.lv6{
					background-size: 100% 100%;
					border-radius: 5px;
					// border: 1px solid rgba(233, 212, 90, 0.358);
					// background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
					background: url('../../assets/images/985/roll/1.png') no-repeat center;
					background-size: 100% 100%;
				}
			}
			.prize_pic {
						width: 120px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 70% 70%;
						background-position: center;
						background-repeat: no-repeat;
						margin-top: 2px;
						img {
							vertical-align: middle;
							max-width: 100%;
							max-height: 100%;
						}
					}

					p {
						// margin-top: 5px;
						font-size: 10px;
						padding: 0 22px;
						width: 87%;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: block;
						// margin-top: 5px;
						text-align: center;
						font-size: 12px;
						color: #f1bc60;
						// margin-top: -20px;
						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
		}
	}
}
	#room {
		width: 100%;
		padding: 0 20px 10px;
		box-sizing: border-box;
		color: #fff;

		.TimeDown {
			width: 200px;
			height: 28px;
			margin: 15px auto 0;
			// background: rgba(48, 57, 81, 0.8);
			// border-bottom: 1px solid #49587e;
			box-sizing: border-box;
			border-radius: 4px;
			display: flex;
			justify-content: center;
			align-items: center;

			.TimeDown_txt {
				font-size: 16px;
				line-height: 20px;
				// margin-right: 5px;
				color: rgb(1, 240, 255);
			}

			.van-count-down {
				
				.colon {
					display: inline-block;
					margin: 0 4px;
					color: #fff;
					font-weight: bold;
					color: rgb(1, 240, 255);
				}

				.block {
					display: inline-block;
					width: 22px;
					background:  rgb(1, 240, 255);;
					// color: #ffba2e;
					font-size: 14px;
					text-align: center;
					border-radius: 5px;
					font-weight: 900;
				}
			}
		}

		.room_data {
			width: 100%;
			margin-top: 15px;
			// background: #20222b;
			// border-radius: 4px;
			// padding: 20px 20px 10px;
			box-sizing: border-box;
			// border: 2px solid #ffba2e;
			background: url('../../assets/images/985/roll/list.png') no-repeat center;
			background-size: 100% 100%;
			padding: 20px;
			.room_top {
				width: 100%;
				display: flex;
				align-items: center;
				// padding-bottom: 15px;
				border-bottom: 2px solid rgba(15, 19, 27, 1.0);
				.room_desc{
					margin-left: 0px;
					width: 80%;
					.room_tit{
						font-size: 15px;
						font-style: italic;
						font-weight: 900;
					}
					p {
						margin-top: 5px;
						text-align: left;
						font-size: 12px;
						line-height: 1.7em;
						overflow-y: auto;
					}
				}
				.room_pic {
					width: 20%;
					height: 100%;
					border-radius: 50%;
					// overflow: hidden;
					margin-right: 10px;

					img {
						// vertical-align: middle;
						width: 100%;
						height: 100%;
						left: 0;
						border: 1px solid #ffba2e ;
						border-radius: 50%;
						margin: 5px;
					}
				}

				
			}

			.room_bottom {
				width: 100%;
				text-align: center;
				.room_info {
					flex: 1;
					
					.room_tit {
						font-size: 16px;
						font-weight: bold;
					}

					p {
						font-size: 12px;
						color: #ccc;
						margin: 5px 0;
						display: inline-block;
						color: rgb(1, 240, 255);
					}

					span {
						font-size: 12px;
						color: rgb(1, 240, 255);
					}
				}
				.room_desc {
					margin-top: 10px;
					
					
				}

				.van-button {
					font-size: 16px;
					margin-top: 10px;
					margin-bottom: 10px;
					color: black !important;
				}

				.van-cell {
					background: none;
					margin-top: 10px;

					.van-field__label {
						width: 32px;
						font-size: 16px;
						color: #fff;
					}

					.van-field__control {
						color: #fff;
					}

					.van-button {
						margin-top: 0;
					}
				}

				.van-cell::after {
					background: #fff;
				}
			}
		}

		.prize {
			width: 100%;
			margin-top: 20px;
			.prize_tit {
					font-size: 16px;
					width: 100%;
					text-align: center;
					line-height: 50px;
					height: 50px;
					margin: 10px 0;
					font-weight: 900;
					font-style: italic;
					// background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATUAAAAvCAYAAACCJV1NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA4RpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ1IDc5LjE2MzQ5OSwgMjAxOC8wOC8xMy0xNjo0MDoyMiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDpmYWUzNGE1ZC03MGI5LWRmNDQtYTk2Ny1iODBiMGY3YTE4NmEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6N0UwMzg2QkQ2OTUyMTFFRDkzN0ZGQ0FFRjM2MzNEREYiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6N0UwMzg2QkM2OTUyMTFFRDkzN0ZGQ0FFRjM2MzNEREYiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIDIwMTkgKFdpbmRvd3MpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTI0NTY3NzYtNmU2Yy1lZTRhLTlhNTYtOWI2MWVkNTRlNjFkIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6MGVhNDFlNjQtODg5Zi04NzQzLWEzNWMtYmQ1Zjc1MmQ0NzllIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+GapSaQAAAOJJREFUeNrs3LEJgDAQQFHjKg7gDE7uDA7gLLEKWChY5NSE9yqrIBafs7iknPNQ0zrN5bHuwUBP0rJvIQePvi3QE1EDRA1A1ABEDUDUAFEDEDUAUQMQNQBRA0QNQNQARA0gRKp99VCxTrOrh4BLy74lkxrAw0mtiYnqdPkk8M101cR7mtSArogaIGoAogYgagCiBogagKgBiBpAJLufwOsidz+rR+20ziRqwG17otau/H4CXRE1QNQARA1A1ABEDRA1AFEDEDUAUQMQNUDUAEQNQNQARA1A1ABRAxA1gB84BBgAUKMjSrZK4y0AAAAASUVORK5CYII=)
      				// no-repeat center;
					background: url('../../assets/images/q2/rollin/title.png') no-repeat center;
					background-size: 70% 110%;
				}
			.prize_nav {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				

				.prize_info {
					display: flex;
					.mores{
						background: #f7d55a;
						border-radius: 5px;
						font-size: 12px;
						padding: 5px;
						color: #000;
						font-weight: 900;
					}
					p {
						font-size: 16px;
						margin-left: 10px;

						span {
							color: #1989fa;
							font-size: 16px;

							img {
								vertical-align: middle;
								width: 18px;
								margin: 0 1px 0 8px;
							}
						}
					}
				}
			}
			.prize_list::-webkit-scrollbar {
			width: 4px;
			}
			.prize_list::-webkit-scrollbar-thumb {
			border-radius: 10px;
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			background: rgba(255, 255, 255, 0.2);
			}
			.prize_list::-webkit-scrollbar-track {
			box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
			border-radius: 0;
			background: rgba(255, 255, 255, 0.1);
			}
			.prize_list {
				width: 100%;
				margin-top: 5px;
				background: #20222b;
				border-radius: 4px;
				overflow: hidden;
				overflow-x: auto;
				white-space: nowrap;
				padding: 5px;
				box-sizing: border-box;

				.prize_item {
					width: 120px;
					height: 85px;
					display: inline-block;
					border-radius: 4px;
					margin-right: 5px;
					background: rgba($color: #fff, $alpha: .03);
					&.lv1{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgba(233, 212, 90, 0.358);
						// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(139, 111, 18));
						background: url('../../assets/images/985/roll/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv2{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(210, 73, 73);
						// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(133, 46, 46));
						background: url('../../assets/images/985/roll/2.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv3{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(203, 70, 203);
						// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(57,44,83));
						background: url('../../assets/images/985/roll/3.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv4{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(86, 86, 214);
						// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(57,79,102));
						background: url('../../assets/images/985/roll/4.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv5{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgb(96, 96, 113);
						// background: linear-gradient(to top, rgba(0, 0, 0, 0.712), rgb(87, 92, 104));
						background: url('../../assets/images/985/roll/5.png') no-repeat center;
						background-size: 100% 100%;
					}
					&.lv6{
						background-size: 100% 100%;
						border-radius: 5px;
    					// border: 1px solid rgba(233, 212, 90, 0.358);
						// background: linear-gradient(to top, rgba(255,0,0,0), rgb(139, 111, 18));
						background: url('../../assets/images/985/roll/1.png') no-repeat center;
						background-size: 100% 100%;
					}
					.prize_pic {
						width: 120px;
						height: 40px;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 70% 70%;
						background-position: center;
						background-repeat: no-repeat;
						margin-top: 2px;
						img {
							vertical-align: middle;
							max-width: 100%;
							max-height: 100%;
						}
					}

					p {
						margin-top: 5px;
						font-size: 10px;
						padding: 0 8px;
						width: 87%;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: block;
						margin-top: 5px;
						text-align: center;
						font-size: 12px;
						color: #f1bc60;

						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
							margin-bottom: 2px;
						}
					}
				}
			}
		}

		.Award {
			width: 100%;
			margin-top: 20px;

			.Award_nav {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.Award_tit {
					font-size: 16px;
				}
			}

			.Award_list {
				width: 100%;
				margin-top: 5px;
				background: #20222b;
				border-radius: 4px;
				overflow: hidden;
				overflow-x: auto;
				white-space: nowrap;
				padding: 5px;
				box-sizing: border-box;

				.Award_item {
					width: 120px;
					height: 95px;
					display: inline-block;
					border-radius: 4px;
					margin-right: 5px;
					background: url('../../assets/images/q2/rollin/1.png');
					background-size: 100% 100%;
					.Award_portrait {
						width: 20px;
						height: 20px;
						// margin: 5px auto;
						border-radius: 50%;
						overflow: hidden;
						border: 1px solid #ffba2e;
						box-sizing: border-box;
						margin-left: 20px;
						img {
							width: 100%;
							height: 100%;
							vertical-align: middle;
						}
					}

					.Award_name {
						font-size: 10px;
						text-align: center;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.Award_pic {
						width: 50px;
						height: 50px;
						margin: 0 auto;
						display: flex;
						align-items: center;
						justify-content: center;
						background-size: 90% 90%;
						background-position: center;
						background-repeat: no-repeat;

						img {
							vertical-align: middle;
							max-width: 100%;
							max-height: 100%;
						}
					}

					p {
						margin-top: 5px;
						font-size: 10px;
						padding: 0 8px;
						text-align: center;
						box-sizing: border-box;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					span {
						display: block;
						margin-top: 5px;
						text-align: center;
						font-size: 10px;
						color: #f1bc60;

						img {
							width: 16px;
							height: 16px;
							vertical-align: middle;
						}
					}
				}
			}
		}

		.player {
			width: 100%;
			margin-top: 20px;
			.player_tit {
				font-size: 16px;
				text-align: center;
				height: 30px;
				line-height: 30px;
				background: url('../../assets/images/q2/rollin/title.png') no-repeat center;
				background-size: 70% 110%;
			}
			.player_nav {
				width: 100%;
				display: flex;
				justify-content: space-between;
				align-items: center;

				

				.player_info {
					display: flex;

					p {
						font-size: 16px;
						margin-left: 10px;

						span {
							color: #1989fa;
						}
					}
				}
			}

			.player_list {
				width: 100%;
				margin-top: 5px;
				background: #20222b;
				border-radius: 4px;
				display: flex;
				flex-wrap: wrap;
				padding: 0 5px;
				box-sizing: border-box;
				min-height: 70px;
				.player_item {
					width: 68px;
					margin-right: 8px;
					text-align: center;
					margin-top: 10px;

					&:nth-child(4n) {
						margin-right: 0;
					}

					.player_pic {
						width: 35px;
						height: 35px;
						margin: 0 auto;
						border-radius: 50%;
						overflow: hidden;
						border: 1px solid #ffba2e;

						img {
							width: 100%;
							height: 100%;
							vertical-align: middle;
						}
					}

					p {
						width: 100%;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-size: 10px;
						margin: 5px 0;
					}
				}
			}
		}
	}
</style>
